export const COLORS = {
  fontColor: "#363135",
  greyColor: "#dbd9db",
  lightGreyColor: "#eaeded",
  hoOrange: "rgb(237, 139, 0)",
  hoDarkerOrange: "b35600",
  hoOrangeDisabled: "#edbb99",
  cgoBlue: "#4281f0",
  cgoDarkerBlue: "#0b3b8e",
  cgoBlueDisabled: "#8abbdf",
  black: "#363135",
  sliderNavColor: "#adacad",
  darkGrey: "#737273",
  red: "#B3150F",
  scarlet: "#ff2400",
  darkRed: "#b32c0e",
  white: "#fff",
  footerColor: "#f6f4f6",
  footerFontColor: "#666",
  shareGreen: "#00c300",
  facebookColor: "#37639e",
  facebookHoverColor: "#2f5587",
  whatsAppColor: "#69ca36",
  messengerGradient: "linear-gradient(90deg, rgba(18,134,249,1) 0%, rgba(156,44,247,1) 50%, rgba(255,80,118,1) 100%",
  messengerHoverGradient: "linear-gradient(90deg, rgba(15, 110, 205, 1) 0%, rgba(127, 35, 202, 1) 50%, rgba(215, 65, 97, 1) 100%",
};
export const FONT_SIZE = {
  h1: "42.7px",
  title: "25px",
  petition_title: "20px",
  medium: "14px",
  small: "12.8px",
  smaller: "10px",
  default: "16px",
};
export const BORDER_RADIUS = "5px";

const sizes = {
  desktop: "1024",
  tablet: "768px",
  mobile: "425px",
};

export const MEDIA_QUERIES = {
  DESKTOP: `(min-width: ${sizes.desktop})`,
  TABLET: `(max-width: ${sizes.tablet})`,
  MOBILE: `(max-width: ${sizes.mobile})`,
};

export const _API_ENDPOINT = {
  join_us: "api/v1/join-us",
  petitions: "api/v1/petition",
  petition: "api/v1/single_petition/",
  members_count: "api/v1/member-count",
  store_shortcut: "api/v1/store-shortcut",
  country_list: "api/get_country_list",
  zip_code_rules: "api/get_country_zipcode_rule",
  sign_petition: "api/sign_petition",
  verify_email: "api/verifyEmail",
  scramble_email: "api/v1/marketo/unsubscription/scramble-email",
  uncheck_myopt: "api/v1/marketo/unsubscription/uncheck-myopt"
}

export const LANGUAGES = [
  {
    code: "de",
    name: "Deutsch",
    otd: "spenden",
    md: "mitglied",
    shareDonation: "LP3-OTD-Form-DE",
    donateShare: "LP2b-OTD-form-DE",
  },
  {
    code: "de-at",
    name: "Deutsch-Österreich",
    otd: "de-at-spenden",
    md: "mitglied",
    shareDonation: "LP3-OTD-Form-DE-AT",
    donateShare: "LP2b-OTD-form-DE_AT",
  },
  {
    code: "de-ch",
    name: "Deutsch-Schweiz",
    otd: "de-ch-spenden",
    md: "mitglied",
    shareDonation: "LP3-OTD-form-DE-CH",
    donateShare: "LP2b-OTD-form-DE_CH",
  },
  // {
  //   code: "en",
  //   name: "English",
  //   otd: "en-USA-donate",
  //   md: "en-USA-donate-membership",
  //   shareDonation: "LP3-OTD-form-EN",
  //   donateShare: "LP2b-OTD-form-EN",
  // },
  {
    code: "en-af",
    name: "English-Africa",
    otd: "en-AF-donate",
    md: "en-af-become-a-member",
    shareDonation: "LP2b-OTD-form-EN",
    donateShare: "LP2b-OTD-form-EN_AF",
  },
  {
    code: "en-au",
    name: "English-Australia",
    otd: "en-AU-donate",
    md: "au-become-a-member",
    shareDonation: "LP3-OTD-form-EN-AU",
    donateShare: "LP2b-OTD-form-EN_AU",
  },
  {
    code: "en-ca",
    name: "English-Canada",
    otd: "en-CA-donate",
    md: "en-CA-donate-membership",
    shareDonation: "LP3-OTD-form-EN-CA",
    donateShare: "LP2b-OTD-form-EN_CA",
  },
  {
    code: "en-ie",
    name: "English-Ireland",
    otd: "en-IE-donate",
    md: "gb-become-a-member2",
    shareDonation: "LP3-OTD-form-EN-IE",
    donateShare: "LP2b-OTD-form-EN_IE",
  },
  {
    code: "en-row",
    name: "English-Other countries",
    otd: "en-ROW-donate",
    md: "en-row-become-a-member",
    shareDonation: "LP3-OTD-form-EN-ROW",
    donateShare: "LP2b-OTD-form-EN_ROW",
  },
  {
    code: "en-gb",
    name: "English-United Kingdom",
    otd: "en-GB-donate",
    md: "gb-become-a-member2",
    shareDonation: "LP3-OTD-form-EN-GB",
    donateShare: "LP2b-OTD-form-EN_GB",
  },
  {
    code: "en-us",
    name: "English-United States",
    otd: "en-USA-donate",
    md: "en-USA-donate-membership",
    shareDonation: "LP3-OTD-form-EN",
    donateShare: "LP2b-OTD-form-EN",
  },
  {
    code: "es-ar",
    name: "Español-Argentina",
    otd: "ar",
    md: "ar-socio",
    shareDonation: "LP3-OTD-form-ES-AR",
    donateShare: "LP2b-OTD-form-ES_AR",
  },
  {
    code: "es-lat",
    name: "Español-América Latina",
    otd: "ltm-donativo",
    md: "ltm-socio",
    shareDonation: "LP3-OTD-form-ES-LAT",
    donateShare: "LP2b-OTD-form-ES_ROW",
  },
  {
    code: "hazteoir",
    name: "Español-España",
    otd: "hazundonativo-ho",
    md: "haztesocio",
    shareDonation: "LP3-OTD-form-ES-HO",
    donateShare: "LP2b-OTD-form-HO",
  },
  {
    code: "es-mx",
    name: "Español-México",
    otd: "mx-donativo",
    md: "mx-socio",
    shareDonation: "LP3-OTD-form-ES-MX",
    donateShare: "LP2b-OTD-form-ES-MX",
  },
  {
    code: "es-us",
    name: "Español-United States",
    otd: "es-us-donativo",
    shareDonation: "LP3-OTD-form-ES-US",
    donateShare: "LP2b-OTD-form-ES-US",
  },
  {
    code: "fr-fr",
    name: "Français",
    otd: "donner",
    md: "fr-membre",
    shareDonation: "LP3-OTD-form-FR",
    donateShare: "LP2b-OTD-form-FR",
  },
  {
    code: "fr-ca",
    name: "Français-Canada",
    otd: "fr-ca-donner",
    shareDonation: "LP3-OTD-form-FR-CA",
  },
  {
    code: "hr",
    name: "Hrvatski",
    otd: "doniraj",
    md: "hr-monthly-donation",
    shareDonation: "LP3-OTD-form-HR",
    donateShare: "LP2b-OTD-form-HR",
  },
  {
    code: "it",
    name: "Italiano",
    otd: "it-sostienici",
    md: "insieme-possiamo",
    shareDonation: "LP3-OTD-form-IT",
    donateShare: "LP2b-OTD-form-IT",
  },
  {
    code: "hu",
    name: "Magyar",
    otd: "hu-donation",
    md: "hu-monthly",
    shareDonation: "LP3-OTD-form-HU",
    donateShare: "LP2b-OTD-form-HU",
  },
  {
    code: "nl",
    name: "Nederlands",
    otd: "doneren",
    md: "word-lid",
    shareDonation: "LP3-OTD-form-NL",
    donateShare: "LP2b-OTD-form-NL",
  },
  {
    code: "tl",
    name: "Filipino",
    otd: "tl-mag-donate",
    md: "tl-maging_miyembro",
    shareDonation: "LP3-OTD-form-tl",
    donateShare: "LP2b-OTD-form-tl",
  },  
  {
    code: "pl",
    name: "Polski",
    otd: "darowizna",
    md: "pl-monthly",
    shareDonation: "LP3-OTD-form-PL",
    donateShare: "LP2b-OTD-form-PL",
  },
  {
    code: "pt-br",
    name: "Português-Brasil",
    otd: "br-doar",
    md: "br-doar",
    shareDonation: "LP3-OTD-form-PT-BR",
    donateShare: "LP2b-OTD-form-PT_BR",
  },
  {
    code: "pt-pt",
    name: "Português-Portugal",
    otd: "pt-doar",
    md: "pt-mensal",
    shareDonation: "LP3-OTD-form-PT-PT",
    donateShare: "LP2b-OTD-form-PT_PT",
  },
  {
    code: "sk",
    name: "Slovenčina",
    otd: "sk-donation",
    md: "sk-monthly",
    shareDonation: "LP3-OTD-form-SK",
    donateShare: "LP2b-OTD-form-SK",
  },
];

export const TESTAPI = "http://localhost:9988/v1/petition/";
export const LOCAL_JSON = "staticData/petitions.json";
export const HOST = `${window.location.protocol}//${window.location.host}`;
export let REMINDER_TIOMEOUT = 180000;

export const OTD_BASE_URL = "https://go.citizengo.org";
export const LP2_SHARE = "LP2-Share";

export function showTop() {
  window.scrollTo({ top: 0, left: 0 });
}
export const REDIRECT_TO_HAZTEOIR = `${window.location.origin}/hazteoir`;
export const REDIRECT_TO_HOME_PAGE = window.location.origin;
export const URL_EXCEPTION_REGEX = /(https?:\/\/|\s|\P{L})/gu;

export const URLParams = (mParam, nid, id, utm_campaign) => {
  return `?m=${mParam}&nid=${nid !== null ? nid : id}${
    utm_campaign ? `&utm_campaign=${utm_campaign}` : ""
  }`;
};

export const topicsShortcutFunc = (topic) => {
  const topicsMap = {
    'oth': 'ot',
    'lfe': 'lf',
    'fam': 'fm',
    'edu': 'ed',
    'sol': 'sd',
    'pol': 'pt',
    'fre': 'fr',
    'ele': 'el',
    'pat': 'pt'
  };
  return topicsMap[topic];
};

export const region = window.location.pathname.split("/")[1];
export const helpscoutURL = "https://help.citizengo.org";

export const aboutHelpscoutId = () => {
  const aboutIdMap = {
    "en.*": "906",
    "de.*": "904",
    "es.*": "921",
    "pt.*": "969",
    hazteoir: "890",
    fr: "905",
    hr: "937",
    hu: "953",
    it: "944",
    pl: "960",
    sk: "984",
    nl: "976",
    tl: "1360",
    default: "896",
  };
  for (const pattern in aboutIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return aboutIdMap[pattern];
    }
  }
};

export const financialStatementHelpscoutId = () => {
  const financialStatementIdMap = {
    "en.*": "892",
    de: "908",
    "es.*": "923",
    "pt.*": "972",
    hazteoir: "898",
    fr: "930",
    hr: "939",
    hu: "955",
    it: "946",
    pl: "963",
    sk: "986",
    nl: "978",
    tl: "1364",
    default: "896",
  };
  for (const pattern in financialStatementIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return financialStatementIdMap[pattern];
    }
  }
};

export const pressHelpscoutId = () => {
  const pressIdMap = {
    "en.*": "895",
    de: "909",
    "es.*": "925",
    "pt.*": "970",
    hazteoir: "https://pressroom.citizengo.com/en",
    fr: "932",
    hr: "941",
    hu: "957",
    it: "948",
    pl: "967",
    sk: "988",
    nl: "980",
    tl: "895", // no id from HelpScout so I used the same as English
    default: "896",
  };
  for (const pattern in pressIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return pressIdMap[pattern];
    }
  }
};

export const privacyHelpscoutId = () => {
  const privacyIdMap = {
    "en.*": "894",
    de: "910",
    "es.*": "926",
    "pt.*": "968",
    hazteoir: "900",
    fr: "935",
    hr: "936",
    hu: "952",
    it: "949",
    pl: "961",
    sk: "989",
    nl: "981",
    tl: "1361",
    default: "896",
  };
  for (const pattern in privacyIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return privacyIdMap[pattern];
    }
  }
};

export const termsHelpscoutId = () => {
  const termsIdMap = {
    "en.*": "907",
    de: "911",
    "es.*": "927",
    "pt.*": "974",
    hazteoir: "903",
    fr: "934",
    hr: "942",
    hu: "958",
    it: "950",
    pl: "965",
    sk: "990",
    nl: "982",
    tl: "1360",
    default: "896",
  };
  for (const pattern in termsIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return termsIdMap[pattern];
    }
  }
};

export const cookiesHelpscoutId = () => {
  const cookiesIdMap = {
    "en.*": "896",
    de: "912",
    "es.*": "928",
    "pt.*": "975",
    hazteoir: "902",
    fr: "933",
    hr: "943",
    hu: "959",
    it: "951",
    pl: "966",
    sk: "991",
    nl: "983",
    tl: "1363",
    default: "896",
  };
  for (const pattern in cookiesIdMap) {
    if (new RegExp(`^${pattern}`).test(region)) {
      return cookiesIdMap[pattern];
    }
  }
};
